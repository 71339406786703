import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

// Antd
import { ConfigProvider } from "antd";

// Utils
import ScrollToTop from "./utils/scrollToTop";
import UserDataInfo from "./utils/UserDataInfo";

// Context
import { GlobalStateProvider } from "./context/GlobalStateProvider";

// Pages
import Home from "./pages/Home";
import WhyMonolith from "./pages/WhyMonolith";
import BecomeAff from "./pages/BecomeAff";
import Products from "./pages/Products";
import ProductItem from "./pages/ProductItem";
import Faq from "./pages/Faq";
import ConditionOfSale from "./pages/ConditionOfSale";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import Contact from "./pages/Contact";
import AbsolutelyMct from "./pages/AbsolutelyMct";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PageNotFound from "./pages/PageNotFound";
import Register from "./pages/Register";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import CommunityBlog from "./pages/CommunityBlog";
import OrderDetails from "./pages/OrderDetails";
import FreeSample from "./pages/FreeSample";

// Layout
import MainLayout from "./layouts/MainLayout";
import GuestCheckout from "./pages/GuestCheckout";
import CommunityBlogItem from "./pages/CommunityBlogItem";
// import MonolithPro from "./pages/MonolithPro";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [openCart, setOpenCart] = useState(false);

  const topbtnDisplay = () => {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  };

  const backToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const stickyTopbarScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY < lastScrollY) {
      setIsScrollingUp(true); // Scrolling up
    } else {
      setIsScrollingUp(false); // Scrolling down
    }

    setLastScrollY(currentScrollY);
  };

  window.onscroll = function () {
    topbtnDisplay();
    stickyTopbarScroll();
  };

  return (
    <Provider store={store}>
      <GlobalStateProvider>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: "var(--primary-bg-color)",
                hoverBorderColor: "rgba(208, 29, 35, .16)",
                activeShadow: "0 0 0 3px rgb(208, 29, 35)",
                paddingBlock: 12,
                borderRadius: 0,
              },
              Select: {
                activeBorderColor: "var(--primary-bg-color)",
                hoverBorderColor: "rgba(208, 29, 35, .16)",
                activeShadow: "0 0 0 3px rgb(208, 29, 35)",
                paddingBlock: 12,
              },
              Menu: {
                darkItemSelectedBg: "#fff",
                horizontalItemHoverColor: "#fff",
                horizontalItemSelectedColor: "#fff",
                itemActiveBg: "#fff",
                itemSelectedBg: "#fff",
                itemSelectedColor: "var(--primary-txt-color)",
                colorPrimaryBorder: "#fff",
              },
              FloatButton: {
                colorBgElevated: "var(--primary-bg-color)",
                colorText: "#fff",
              },
              Tabs: {
                inkBarColor: "var(--primary-txt-color)",
                itemActiveColor: "var(--primary-txt-color)",
                itemHoverColor: "var(--primary-txt-color)",
                itemSelectedColor: "var(--primary-txt-color)",
              },
              Radio: {
                buttonSolidCheckedActiveBg: "var(--primary-txt-color)",
                buttonSolidCheckedBg: "var(--primary-txt-color)",
                buttonSolidCheckedHoverBg: "var(--primary-txt-color)",
                colorPrimary: "var(--primary-txt-color)",
                colorPrimaryActive: "var(--primary-txt-color)",
              },
              Checkbox: {
                colorPrimary: "var(--primary-txt-color)",
                colorPrimaryBorder: "var(--primary-txt-color)",
                colorPrimaryHover: "var(--primary-txt-color)",
              },
            },
            token: {
              borderRadius: 0,
            },
          }}
        >
          <div className="App">
            <Router>
              <ScrollToTop />
              <UserDataInfo />
              <Routes>
                <Route
                  path="/"
                  element={
                    <MainLayout
                      isScrollingUp={isScrollingUp}
                      openCart={openCart}
                      setOpenCart={setOpenCart}
                      showTopBtn={showTopBtn}
                      backToTop={backToTop}
                    />
                  }
                >
                  <Route index element={<Home />} />
                  <Route
                    path="/about/why-monolith-nutrition"
                    element={<WhyMonolith />}
                  />
                  <Route path="/lifestyle-affiliate" element={<BecomeAff />} />
                  <Route
                    path="/products"
                    element={
                      <Products openCart={openCart} setOpenCart={setOpenCart} />
                    }
                  />
                  <Route
                    path="mct/:name"
                    element={
                      <ProductItem
                        openCart={openCart}
                        setOpenCart={setOpenCart}
                      />
                    }
                  />
                  <Route path="/faq" element={<Faq />} />
                  <Route
                    path="/condition-of-sale"
                    element={<ConditionOfSale />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/return-policy" element={<ReturnPolicy />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/about/why-mct" element={<AbsolutelyMct />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/password" element={<ResetPassword />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/order" element={<OrderDetails failed="1" />} />
                  <Route
                    path="/order/success"
                    element={<OrderDetails failed="0" />}
                  />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="/page-not-found" element={<PageNotFound />} />
                  <Route path="/account" element={<Account />} />
                  {/* <Route path="/monolith-pro" element={<MonolithPro />} /> */}
                  <Route path="/community-blog" element={<CommunityBlog />} />
                  <Route
                    path="/community-blog/:blog"
                    element={<CommunityBlogItem />}
                  />
                  <Route path="/free-sample" element={<FreeSample />} />
                </Route>

                <Route path="/checkout" element={<Checkout />} />
                <Route path="/guest-checkout" element={<GuestCheckout />} />
              </Routes>
            </Router>
          </div>
        </ConfigProvider>
      </GlobalStateProvider>
    </Provider>
  );
}

export default App;
