import React from "react";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// Scoped CSS
import styles from "./Banner.module.scss";

// Images
import webBannerImg from "../../assets/images/banner/web_banner.png";
import mobBannerImg from "../../assets/images/banner/mob_banner.png";

// Context
import { useGlobalState } from "../../context/GlobalStateProvider";

const bannerImages = [webBannerImg];

const mobileBannerImages = [mobBannerImg];

function Banner() {
  // const [swiperBanner, setSwiperBanner] = useState();
  // const [isPlay, setIsPlay] = useState(true);
  const { isMobile } = useGlobalState();

  return (
    <div className={`${styles.banner} p-relative`}>
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        navigation={true}
        loop={bannerImages?.length > 1}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: "fraction",
        }}
        // onSwiper={setSwiperBanner}
        className="mySwiper home-banner"
      >
        {!isMobile &&
          bannerImages.map((el, i) => (
            <SwiperSlide key={`banner-img-${i}`}>
              <Link to="/mct/essentials">
                <img className={styles.bannerImg} src={el} alt="banner" />
              </Link>
            </SwiperSlide>
          ))}
        {isMobile &&
          mobileBannerImages.map((el, i) => (
            <SwiperSlide key={`banner-img-${i}`}>
              <Link to="/mct/essentials">
                <img className={styles.bannerImg} src={el} alt="banner" />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>

      {/* <div className="p-absolute bottom-20 left-50 z-index-2">
        <button className="m-r-10" onClick={() => swiperBanner.autoplay.stop()}>
          Pause
        </button>
        <button onClick={() => swiperBanner.autoplay.start()}>Resume</button>
      </div> */}
    </div>
  );
}

export default Banner;
