import React from "react";
import { Link } from "react-router-dom";
import { essentialProductId, leanProductId } from "./constants";

// Product Images Start
import essentialBox1 from "../assets/images/box_MCT Essentials_01.png";
import essentialBox2 from "../assets/images/MCT-Essentials-Panel-02.jpg";
import essentialBox3 from "../assets/images/MCT-Essentials-Panel-03.jpg";

import leanEssentialBox1 from "../assets/images/box_MCT LeanEssentials_01.png";
import leanEssentialBox2 from "../assets/images/MCT-LeanEssentials-Panel-02.jpg";
import leanEssentialBox3 from "../assets/images/MCT-LeanEssentials-Panel-03.jpg";

import essentialsFeature1Img from "../assets/images/essentials_feature_1.png";
import essentialsFeature2Img from "../assets/images/essentials_feature_2.png";
import essentialsFeature3Img from "../assets/images/essentials_feature_3.png";
import essentialsFeature4Img from "../assets/images/essentials_feature_4.png";
import essentialsFeature5Img from "../assets/images/essentials_feature_5.png";
import essentialsFeature6Img from "../assets/images/essentials_feature_6.png";

import leanFeature1Img from "../assets/images/Monolith Nutrition-MCT LeanEssentials-Sachet-Packs.png";
import leanFeature2Img from "../assets/images/mct-whey-powder-easy-mix.jpg";
import leanFeature3Img from "../assets/images/lean_feature_3.png";
import leanFeature4Img from "../assets/images/lean_feature_4.png";
import leanFeature5Img from "../assets/images/lean_feature_5.png";
import leanFeature6Img from "../assets/images/lean_feature_6.png";
// Product Images End

// Community blog start
import testimonialImg1 from "../assets/images/testimonials/testimonial-01.jpg";
import testimonialImg2 from "../assets/images/testimonials/testimonial-02.jpg";
import testimonialImg3 from "../assets/images/testimonials/testimonial-03.jpg";
import testimonialImg4 from "../assets/images/testimonials/testimonial-04.jpg";
import testimonialImg5 from "../assets/images/testimonials/testimonial-05.jpg";

import kenImg from "../assets/images/community-blog/ken.png";
import philipImg from "../assets/images/community-blog/philip.png";
import elderYogaImg from "../assets/images/community-blog/elder-yoga.jpg";
import fitMaleImg from "../assets/images/community-blog/fit-male.jpg";
import coconutImg from "../assets/images/community-blog/coconut.png";
import sachetImg from "../assets/images/community-blog/sachet.png";
import kenBanner from "../assets/images/community-blog/ken-banner.jpg";
import philipBanner from "../assets/images/community-blog/philip-banner.jpg";
// Community blog end

export const isPro = "0";

// Product Start
export const productList = {
  essentials: {
    id: essentialProductId,
    path: "essentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT ESSENTIALS</div> },
    ],
    productName: <div className="big-title m-b-5">MCT ESSENTIALS</div>,
    nameString: "MCT ESSENTIALS",
    productDescHead:
      "100% MCT from coconut with Postbiotics Proprietary Matrix",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) ESSENTIALS is the core foundation from which you build your nutritional supplementation regimen upon. Each box contains 24 convenient ready to drink lifestyle sachets that improve gut health, weight management, and immune health.",
    price: 198,
    size: "20 / box",
    flavour: "Lemon Blast",
    productImages: [essentialBox1, essentialBox2, essentialBox3],
    productFeatureImages: [
      {
        img: essentialsFeature1Img,
        alt: "20 Convenient Lifestyle Sachets",
      },
      {
        img: essentialsFeature2Img,
        alt: "Tasty and Refreshing Lemon Blast Flavour",
      },
      {
        img: essentialsFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
      },
      {
        img: essentialsFeature4Img,
        alt: "100% Coconut MCT",
      },
      {
        img: essentialsFeature5Img,
        alt: "The Latest Achievement In Gut Health",
      },
      {
        img: essentialsFeature6Img,
        alt: "Pairs Great with MCT LeanEssentials for Protein Intake",
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">5g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">20 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                <div className="m-b-20">
                  Take 1 – 2 sachets a day in the morning and/or evening,
                  preferably between meals on an empty stomach. Mix a sachet
                  with 250ml of cold or room temperature water.
                </div>
                <div>
                  MCT Essentials is designed for superior taste and mixability.
                  Add a sachet of MCT Essentials into your usual morning
                  smoothie shake, or with certain fruit juices to create a mixed
                  punch taste. Be creative, make it fun and mix it up! Check out
                  our community blog for ideas!
                </div>
              </div>
              <div className="paragraph title">Important Notes</div>
              <div className="paragraph content">
                Due to the natural fragility of the postbiotics matrix
                ingredients, do not mix with hot water.
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Coconut MCT (Medium-chain triglycerides), Acacia Fibre, Lemon
              Powder, Postbiotics, d-Alpha-Tocotrienol, Guar Gum (E412) as
              permitted stabilizer and Sucralose (E955) as permitted sweetener.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (5g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">0.1mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">5.2mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">3.4g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">1.2g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">0.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">0g</div>
            </div>
          </div>
        ),
      },
    ],
  },
  leanessentials: {
    id: leanProductId,
    path: "leanessentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT Lean Essentials</div> },
    ],
    productName: (
      <div className="big-title m-b-5">
        MCT <span className="fw-300">LEAN</span>ESSENTIALS
      </div>
    ),
    nameString: "MCT LEANESSENTIALS",
    productDescHead:
      "High protein ready to drink nutritional shake with 100% coconut MCT and a prebiotics matrix.",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) LEANESSENTIALS is packed with whey protein concentrate (WPC80) goodness. Each box contains 15 convenient ready to drink lifestyle sachets power-packed with all the benefits of MCT and a superior protein source from whey.",
    price: 198,
    size: "15 / box",
    flavour: "Chocolate Rapture",
    productImages: [leanEssentialBox1, leanEssentialBox2, leanEssentialBox3],
    productFeatureImages: [
      {
        img: leanFeature1Img,
        alt: "15 Convenient Lifestyle Sachets",
        needBorder: true,
      },
      {
        img: leanFeature2Img,
        alt: "Hearty and Addictive Chocolate Rapture Flavour",
        needBorder: true,
      },
      {
        img: leanFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
        needBorder: false,
      },
      {
        img: leanFeature4Img,
        alt: "100% Coconut MCT",
        needBorder: false,
      },
      {
        img: leanFeature5Img,
        alt: "The Latest Achievement In Gut Health",
        needBorder: false,
      },
      {
        img: leanFeature6Img,
        alt: "Pairs Strongly with Foundational MCT Essentials",
        needBorder: false,
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">40g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">15 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                Take 1 – 2 sachets a day. Best consumed before breakfast or
                between meals. Enjoy a nutritious and great tasting shake by
                mixing a sachet with 250 – 300ml of water, milk, tea or coffee.
                Designed for superior taste and mixability. Best enjoyed cold
                and chilled. Store product in a cool, dry place.
                <br />
                <br />
                Enjoy a hearty and nutritious shake by mixing a sachet with 250
                – 300ml of water, milk, tea or coffee. MCT LeanEssentials is
                designed to absolutely mix and taste great. Mix a sachet of MCT
                LeanEssentials into cold milk to enjoy with cereal, or blend
                into a delicious smoothie shake with your favourite fruits,
                oats, or nuts. The Chocolate Rapture flavour lends itself to
                adding a taste profile that rivals your favourite cocoa or
                coffee smoothie shake so go wild and be creative! Check out our
                community blog for fun (and more importantly, healthy) serving
                ideas!
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Whey Protein Concentrate, Cocoa Powder, Non-dairy Creamer, Sugar,
              Proprietary Coconut Dietary Fibre, Prebiotics
              (Fructooligosaccharide), Coconut MCT (Medium-chain triglycerides),
              Acacia Fibre, Guar Gum (INS 412) as permitted stabilizer,
              Sucralose (INS 955) as permitted sweetener and permitted
              flavoring.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (40g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">3.5g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">2.5g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">33mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">132mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">17.6g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">6g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">8.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">15g</div>
            </div>
          </div>
        ),
      },
    ],
  },
};
// Product End

// Community Blog Start
export const categories = [
  { label: "MCT", value: "mct" },
  { label: "Lifestyle", value: "lifestyle" },
  // { label: "Fitness", value: "fitness" },
  // { label: "Cognitive", value: "cognitive" },
  // { label: "Events", value: "events" },
];

export const categoryContent = {
  mct: [
    {
      title: "Mind Your Business",
      author: {
        name: "Ken Khang Nanyi",
        about:
          "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
        profileImg: kenImg,
      },
      desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
      path: "mind-your-business",
      categories: ["LifeStyle", "Beginners"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            Whatever career, profession, or task you undertake, you are
            essentially doing some form of business. You put in the work
            necessary to achieve your goals so that you get rewarded for your
            efforts. You strive to be competent and relevant in your job, field,
            and industry in order to deliver value. You learn to work smart and
            use leverage so that you can get more done everyday. You work with
            the intention of creating passive income, or for a pension or reward
            scheme that keeps you comfortable for retirement.
            <br />
            <br />
            Now, let us enlighten ourselves as to how similar building and
            maintaining a healthy body is to building a business.
            <br />
            You put in place a lifestyle that helps you stay fit physically and
            mentally. You strive to commit to exercise so that you stay healthy
            for a better quality of life. You learn to leverage on knowledge and
            nutrition so that your efforts become greater. You build a
            foundation for good health towards enjoying a long and fulfilling
            life that is free from sickness and disease.
            <br />
            <br />
            See the similarities in concept and essence? Your body is literally
            a business – <span className="font-bold">YOUR</span> business.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Building a solid foundation</div>
            A successful business or career thrives when a solid foundation
            manifests itself from your knowledge, skills and abilities. With
            this foundation, you open up the ability to create sustainable
            action and systems for further income streams.
            <br />
            <br />
            Your overall well-being and quality of life relies on a healthy
            foundation to flourish upon. Neglecting foundational health risks
            weakening the immune system, increasing susceptibility to sickness
            and disease, and giving way to chronic illnesses.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Staying informed and relevant in a changing environment
            </div>
            The business landscape has evolved and individuals and organisations
            must adapt in order to stay relevant. It’s cliche but it’s true when
            they say change is the only constant. But there are fundamentals
            that consistently work and remain relevant since time immemorial,
            and that is creating value through productive outputs.
            <br />
            <br />
            With new advances, research, and knowledge in health and wellness,
            it is important to stay in the loop of current information that is
            relevant to good health and improving your quality of life through
            wellness.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Use it or lose it</div>
            Use your knowledge, skills and abilities to create value; or do not
            and let it eventually become forgotten and go to waste. The muscles
            of our body when not frequently used eventually atrophy, leading to
            infirmities and even sarcopenia. Our mind and brain deteriorates
            through time without mental stimulation across the thought and
            learning process, leading to a feeble mind and even senility.
            <br />
            <br />
            Make effort and take action to use your mind and muscles, or
            eventually lose it.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={elderYogaImg}
                alt="elder doing yoga"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Maintaining healthy muscle is vital for longevity and a more
                fulfilling quality of life.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Aspire to create passive reward streams
            </div>
            We strive to achieve some form of passive income or an appropriate
            nest egg that allows us to maintain a desired lifestyle, enjoy
            comfortably long way down our golden years, or leave behind a
            legacy. When a profitable foundation and sustainable system is in
            place, income continues well into the times even when you stop
            working. The natural process of thermogenesis occurs in our body
            throughout the day even while we are resting or sleeping. This is
            part of our body’s metabolism and when harnessed, creates an ideal
            and natural environment for optimum weight loss and healthy physical
            function. outputs.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={fitMaleImg}
                alt="fit male"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                {
                  "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
                }
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Your takeaway for a great day</div>
            In both business and life in general, making small efforts and
            lifestyle tweaks today definitely helps you enjoy tomorrow and
            beyond so much more. So mind your business starting from today!
          </div>
        </div>
      ),
      banner: kenBanner,
    },
    {
      title: "Our belief towards good health & better living",
      author: {
        name: "Philip Tan",
        about:
          "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
        profileImg: philipImg,
      },
      desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
      path: "our-belief-towards-good-health-and-better-living",
      categories: ["About Us"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            It’s one thing to do something, and another to truly believe it in.
            When we truly believe in something, we put all of our heart, mind,
            body, and soul into our actions and tasks. There’s just no faking
            it.
            <br />
            <br />
            Monolith Nutrition was the culmination of three years of business
            strategy brainstorming, nutritional research, product formulation
            and tweaking, bonding and team-building, and belief-driven passion.
            All of which resulted in the creation of a values and beliefs system
            that drives our decision-making towards the pursuit of delivering
            nutritional supplements that truly promote good health and better
            living across the lives that every humble sachet touches.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Good Health & Better Living</div>
            We are laser-focused in formulating foundational nutrition
            supplements that are high quality and of great value for good health
            and better living. Whether it is MCT derived from USDA certified
            organic coconuts, whey protein concentrate, coconut dietary fibre,
            European-sourced natural flavourings, or high bioavailability
            tocotrienol, expect the best ingredients used across strict
            formulation and manufacturing standards.
            <br />
            <br />
            Our flagship products MCT Essentials and MCT LeanEssentials
            represent high quality MCT nutritional supplements that do not break
            the bank and are suitable for long-term consumption.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Differentiation</div>
            Know that you are using nutritional supplements that are unique and
            stand out in their own way. We know that MCT is great for health,
            but we differentiate ourselves by standing out from the rest by
            packing as much coconut MCT rather than filler into every serving
            sachet. We are proud to have created a tantalising taste profile
            that makes you crave for the next sachet. We have painstakingly
            formulated an easy to mix MCT powdered product that mixes amazingly
            well with both cold and hot water or drinks – something that many
            find difficult without the use of fillers.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Sustainability</div>
            Our business actions and activities aim to reduce carbon footprint.
            Monolith Nutrition avoids using plastic wrap for our products, and
            our box paper packaging is derived from recycled materials and
            sustainable forestry. Product box printing utilises sustainable and
            eco-friendly vegetable-based inks. MCT Essentials’ small and chic
            box and sachet sizes use fewer resources, and we take it a step
            further with paper packaging carton boxes that fit as many products
            as possible to further reduce use of paper carton boxes.
            <br />
            <br />
            Monolith Nutrition’s sourced USDA certified organic coconuts – the
            prime ingredient used for our MCT – are sourced from suppliers who
            practise sustainable farming methods.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={coconutImg}
                alt="coconut"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Quality MCT sourced from USDA certified organic coconuts.
                Consume with confidence!
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Contemporary</div>
            <div>
              MCT Essentials and MCT LeanEssentials truly represents a modern
              and contemporary way to enjoy all the benefits of MCT in a
              convenient and enjoyable manner. Easily mixed with your favourite
              drinks – whether cold or hot – or blend the sachet’s contents of
              flavourful Lemon Blast powder or hearty Chocolate Rapture powder
              into a nutritious shake or smoothie that is not only super healthy
              but addictively tasty.
            </div>

            <br />
            <br />

            <div>
              Furthermore, in chic sachet form, take them on the go with you and
              consume at your convenience day or night, sunshine or rain! Even
              the box itself is small enough to bring along with you!
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={sachetImg}
                alt="MCT sachet"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Contemporary, chic and convenient sachets perfect for everyday
                free and easy or even on the go lifestyles.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Innovation</div>
            <div>
              The fundamentals for healthy living has not changed and remains as
              simple and straightforward as it has always been. However, with
              new ideas, research, and advances in nutrition, exercise, modern
              medicine, and an overall improved quality of life, there are more
              options today than ever before when it comes to choosing the right
              solutions we need and desire to better improve health and
              wellness.
            </div>

            <br />
            <br />

            <div>
              With economies of scale, nutritional experience, an equipped
              facility, and technical know-how, Monolith Nutrition avoids the
              common OEM and ODM method of product formulation and production.
              We innovate across the design, formulation, and process of the
              product from start to finish, allowing us to customise and create
              MCT products that are unique and one of a kind.
            </div>

            <br />
            <br />

            <div>
              We wholeheartedly believe that you hold in your hands a
              nutritional supplement that will pay dividends to your overall
              health and wellness. Be confident that every MCT Essentials or MCT
              LeanEssentials sachet that you take gets you closer to good health
              and better living.
            </div>
          </div>
        </div>
      ),
      banner: philipBanner,
    },
  ],
  lifestyle: [
    {
      title: "Mind Your Business",
      author: {
        name: "Ken Khang Nanyi",
        about:
          "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
        profileImg: kenImg,
      },
      desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
      path: "mind-your-business",
      categories: ["LifeStyle", "Beginners"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            Whatever career, profession, or task you undertake, you are
            essentially doing some form of business. You put in the work
            necessary to achieve your goals so that you get rewarded for your
            efforts. You strive to be competent and relevant in your job, field,
            and industry in order to deliver value. You learn to work smart and
            use leverage so that you can get more done everyday. You work with
            the intention of creating passive income, or for a pension or reward
            scheme that keeps you comfortable for retirement.
            <br />
            <br />
            Now, let us enlighten ourselves as to how similar building and
            maintaining a healthy body is to building a business.
            <br />
            You put in place a lifestyle that helps you stay fit physically and
            mentally. You strive to commit to exercise so that you stay healthy
            for a better quality of life. You learn to leverage on knowledge and
            nutrition so that your efforts become greater. You build a
            foundation for good health towards enjoying a long and fulfilling
            life that is free from sickness and disease.
            <br />
            <br />
            See the similarities in concept and essence? Your body is literally
            a business – <span className="font-bold">YOUR</span> business.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Building a solid foundation</div>
            A successful business or career thrives when a solid foundation
            manifests itself from your knowledge, skills and abilities. With
            this foundation, you open up the ability to create sustainable
            action and systems for further income streams.
            <br />
            <br />
            Your overall well-being and quality of life relies on a healthy
            foundation to flourish upon. Neglecting foundational health risks
            weakening the immune system, increasing susceptibility to sickness
            and disease, and giving way to chronic illnesses.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Staying informed and relevant in a changing environment
            </div>
            The business landscape has evolved and individuals and organisations
            must adapt in order to stay relevant. It’s cliche but it’s true when
            they say change is the only constant. But there are fundamentals
            that consistently work and remain relevant since time immemorial,
            and that is creating value through productive outputs.
            <br />
            <br />
            With new advances, research, and knowledge in health and wellness,
            it is important to stay in the loop of current information that is
            relevant to good health and improving your quality of life through
            wellness.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Use it or lose it</div>
            Use your knowledge, skills and abilities to create value; or do not
            and let it eventually become forgotten and go to waste. The muscles
            of our body when not frequently used eventually atrophy, leading to
            infirmities and even sarcopenia. Our mind and brain deteriorates
            through time without mental stimulation across the thought and
            learning process, leading to a feeble mind and even senility.
            <br />
            <br />
            Make effort and take action to use your mind and muscles, or
            eventually lose it.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={elderYogaImg}
                alt="elder doing yoga"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Maintaining healthy muscle is vital for longevity and a more
                fulfilling quality of life.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Aspire to create passive reward streams
            </div>
            We strive to achieve some form of passive income or an appropriate
            nest egg that allows us to maintain a desired lifestyle, enjoy
            comfortably long way down our golden years, or leave behind a
            legacy. When a profitable foundation and sustainable system is in
            place, income continues well into the times even when you stop
            working. The natural process of thermogenesis occurs in our body
            throughout the day even while we are resting or sleeping. This is
            part of our body’s metabolism and when harnessed, creates an ideal
            and natural environment for optimum weight loss and healthy physical
            function. outputs.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={fitMaleImg}
                alt="fit male"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                {
                  "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
                }
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Your takeaway for a great day</div>
            In both business and life in general, making small efforts and
            lifestyle tweaks today definitely helps you enjoy tomorrow and
            beyond so much more. So mind your business starting from today!
          </div>
        </div>
      ),
      banner: kenBanner,
    },
    {
      title: "Our belief towards good health & better living",
      author: {
        name: "Philip Tan",
        about:
          "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
        profileImg: philipImg,
      },
      desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
      path: "our-belief-towards-good-health-and-better-living",
      categories: ["About Us"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            It’s one thing to do something, and another to truly believe it in.
            When we truly believe in something, we put all of our heart, mind,
            body, and soul into our actions and tasks. There’s just no faking
            it.
            <br />
            <br />
            Monolith Nutrition was the culmination of three years of business
            strategy brainstorming, nutritional research, product formulation
            and tweaking, bonding and team-building, and belief-driven passion.
            All of which resulted in the creation of a values and beliefs system
            that drives our decision-making towards the pursuit of delivering
            nutritional supplements that truly promote good health and better
            living across the lives that every humble sachet touches.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Good Health & Better Living</div>
            We are laser-focused in formulating foundational nutrition
            supplements that are high quality and of great value for good health
            and better living. Whether it is MCT derived from USDA certified
            organic coconuts, whey protein concentrate, coconut dietary fibre,
            European-sourced natural flavourings, or high bioavailability
            tocotrienol, expect the best ingredients used across strict
            formulation and manufacturing standards.
            <br />
            <br />
            Our flagship products MCT Essentials and MCT LeanEssentials
            represent high quality MCT nutritional supplements that do not break
            the bank and are suitable for long-term consumption.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Differentiation</div>
            Know that you are using nutritional supplements that are unique and
            stand out in their own way. We know that MCT is great for health,
            but we differentiate ourselves by standing out from the rest by
            packing as much coconut MCT rather than filler into every serving
            sachet. We are proud to have created a tantalising taste profile
            that makes you crave for the next sachet. We have painstakingly
            formulated an easy to mix MCT powdered product that mixes amazingly
            well with both cold and hot water or drinks – something that many
            find difficult without the use of fillers.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Sustainability</div>
            Our business actions and activities aim to reduce carbon footprint.
            Monolith Nutrition avoids using plastic wrap for our products, and
            our box paper packaging is derived from recycled materials and
            sustainable forestry. Product box printing utilises sustainable and
            eco-friendly vegetable-based inks. MCT Essentials’ small and chic
            box and sachet sizes use fewer resources, and we take it a step
            further with paper packaging carton boxes that fit as many products
            as possible to further reduce use of paper carton boxes.
            <br />
            <br />
            Monolith Nutrition’s sourced USDA certified organic coconuts – the
            prime ingredient used for our MCT – are sourced from suppliers who
            practise sustainable farming methods.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={coconutImg}
                alt="coconut"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Quality MCT sourced from USDA certified organic coconuts.
                Consume with confidence!
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Contemporary</div>
            <div>
              MCT Essentials and MCT LeanEssentials truly represents a modern
              and contemporary way to enjoy all the benefits of MCT in a
              convenient and enjoyable manner. Easily mixed with your favourite
              drinks – whether cold or hot – or blend the sachet’s contents of
              flavourful Lemon Blast powder or hearty Chocolate Rapture powder
              into a nutritious shake or smoothie that is not only super healthy
              but addictively tasty.
            </div>

            <br />
            <br />

            <div>
              Furthermore, in chic sachet form, take them on the go with you and
              consume at your convenience day or night, sunshine or rain! Even
              the box itself is small enough to bring along with you!
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={sachetImg}
                alt="MCT sachet"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Contemporary, chic and convenient sachets perfect for everyday
                free and easy or even on the go lifestyles.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Innovation</div>
            <div>
              The fundamentals for healthy living has not changed and remains as
              simple and straightforward as it has always been. However, with
              new ideas, research, and advances in nutrition, exercise, modern
              medicine, and an overall improved quality of life, there are more
              options today than ever before when it comes to choosing the right
              solutions we need and desire to better improve health and
              wellness.
            </div>

            <br />
            <br />

            <div>
              With economies of scale, nutritional experience, an equipped
              facility, and technical know-how, Monolith Nutrition avoids the
              common OEM and ODM method of product formulation and production.
              We innovate across the design, formulation, and process of the
              product from start to finish, allowing us to customise and create
              MCT products that are unique and one of a kind.
            </div>

            <br />
            <br />

            <div>
              We wholeheartedly believe that you hold in your hands a
              nutritional supplement that will pay dividends to your overall
              health and wellness. Be confident that every MCT Essentials or MCT
              LeanEssentials sachet that you take gets you closer to good health
              and better living.
            </div>
          </div>
        </div>
      ),
      banner: philipBanner,
    },
  ],
  // fitness: [
  //   {
  //     title: "Mind Your Business",
  //     author: {
  //       name: "Ken Khang Nanyi",
  //       about:
  //         "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
  //       profileImg: kenImg,
  //     },
  //     desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
  //     path: "mind-your-business",
  //     categories: ["LifeStyle", "Beginners"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           Whatever career, profession, or task you undertake, you are
  //           essentially doing some form of business. You put in the work
  //           necessary to achieve your goals so that you get rewarded for your
  //           efforts. You strive to be competent and relevant in your job, field,
  //           and industry in order to deliver value. You learn to work smart and
  //           use leverage so that you can get more done everyday. You work with
  //           the intention of creating passive income, or for a pension or reward
  //           scheme that keeps you comfortable for retirement.
  //           <br />
  //           <br />
  //           Now, let us enlighten ourselves as to how similar building and
  //           maintaining a healthy body is to building a business.
  //           <br />
  //           You put in place a lifestyle that helps you stay fit physically and
  //           mentally. You strive to commit to exercise so that you stay healthy
  //           for a better quality of life. You learn to leverage on knowledge and
  //           nutrition so that your efforts become greater. You build a
  //           foundation for good health towards enjoying a long and fulfilling
  //           life that is free from sickness and disease.
  //           <br />
  //           <br />
  //           See the similarities in concept and essence? Your body is literally
  //           a business – <span className="font-bold">YOUR</span> business.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Building a solid foundation</div>
  //           A successful business or career thrives when a solid foundation
  //           manifests itself from your knowledge, skills and abilities. With
  //           this foundation, you open up the ability to create sustainable
  //           action and systems for further income streams.
  //           <br />
  //           <br />
  //           Your overall well-being and quality of life relies on a healthy
  //           foundation to flourish upon. Neglecting foundational health risks
  //           weakening the immune system, increasing susceptibility to sickness
  //           and disease, and giving way to chronic illnesses.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Staying informed and relevant in a changing environment
  //           </div>
  //           The business landscape has evolved and individuals and organisations
  //           must adapt in order to stay relevant. It’s cliche but it’s true when
  //           they say change is the only constant. But there are fundamentals
  //           that consistently work and remain relevant since time immemorial,
  //           and that is creating value through productive outputs.
  //           <br />
  //           <br />
  //           With new advances, research, and knowledge in health and wellness,
  //           it is important to stay in the loop of current information that is
  //           relevant to good health and improving your quality of life through
  //           wellness.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Use it or lose it</div>
  //           Use your knowledge, skills and abilities to create value; or do not
  //           and let it eventually become forgotten and go to waste. The muscles
  //           of our body when not frequently used eventually atrophy, leading to
  //           infirmities and even sarcopenia. Our mind and brain deteriorates
  //           through time without mental stimulation across the thought and
  //           learning process, leading to a feeble mind and even senility.
  //           <br />
  //           <br />
  //           Make effort and take action to use your mind and muscles, or
  //           eventually lose it.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={elderYogaImg}
  //               alt="elder doing yoga"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Maintaining healthy muscle is vital for longevity and a more
  //               fulfilling quality of life.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Aspire to create passive reward streams
  //           </div>
  //           We strive to achieve some form of passive income or an appropriate
  //           nest egg that allows us to maintain a desired lifestyle, enjoy
  //           comfortably long way down our golden years, or leave behind a
  //           legacy. When a profitable foundation and sustainable system is in
  //           place, income continues well into the times even when you stop
  //           working. The natural process of thermogenesis occurs in our body
  //           throughout the day even while we are resting or sleeping. This is
  //           part of our body’s metabolism and when harnessed, creates an ideal
  //           and natural environment for optimum weight loss and healthy physical
  //           function. outputs.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={fitMaleImg}
  //               alt="fit male"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               {
  //                 "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
  //               }
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Your takeaway for a great day</div>
  //           In both business and life in general, making small efforts and
  //           lifestyle tweaks today definitely helps you enjoy tomorrow and
  //           beyond so much more. So mind your business starting from today!
  //         </div>
  //       </div>
  //     ),
  //     banner: kenBanner,
  //   },
  //   {
  //     title: "Our belief towards good health & better living",
  //     author: {
  //       name: "Philip Tan",
  //       about:
  //         "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
  //       profileImg: philipImg,
  //     },
  //     desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
  //     path: "our-belief-towards-good-health-and-better-living",
  //     categories: ["About Us"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           It’s one thing to do something, and another to truly believe it in.
  //           When we truly believe in something, we put all of our heart, mind,
  //           body, and soul into our actions and tasks. There’s just no faking
  //           it.
  //           <br />
  //           <br />
  //           Monolith Nutrition was the culmination of three years of business
  //           strategy brainstorming, nutritional research, product formulation
  //           and tweaking, bonding and team-building, and belief-driven passion.
  //           All of which resulted in the creation of a values and beliefs system
  //           that drives our decision-making towards the pursuit of delivering
  //           nutritional supplements that truly promote good health and better
  //           living across the lives that every humble sachet touches.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Good Health & Better Living</div>
  //           We are laser-focused in formulating foundational nutrition
  //           supplements that are high quality and of great value for good health
  //           and better living. Whether it is MCT derived from USDA certified
  //           organic coconuts, whey protein concentrate, coconut dietary fibre,
  //           European-sourced natural flavourings, or high bioavailability
  //           tocotrienol, expect the best ingredients used across strict
  //           formulation and manufacturing standards.
  //           <br />
  //           <br />
  //           Our flagship products MCT Essentials and MCT LeanEssentials
  //           represent high quality MCT nutritional supplements that do not break
  //           the bank and are suitable for long-term consumption.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Differentiation</div>
  //           Know that you are using nutritional supplements that are unique and
  //           stand out in their own way. We know that MCT is great for health,
  //           but we differentiate ourselves by standing out from the rest by
  //           packing as much coconut MCT rather than filler into every serving
  //           sachet. We are proud to have created a tantalising taste profile
  //           that makes you crave for the next sachet. We have painstakingly
  //           formulated an easy to mix MCT powdered product that mixes amazingly
  //           well with both cold and hot water or drinks – something that many
  //           find difficult without the use of fillers.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Sustainability</div>
  //           Our business actions and activities aim to reduce carbon footprint.
  //           Monolith Nutrition avoids using plastic wrap for our products, and
  //           our box paper packaging is derived from recycled materials and
  //           sustainable forestry. Product box printing utilises sustainable and
  //           eco-friendly vegetable-based inks. MCT Essentials’ small and chic
  //           box and sachet sizes use fewer resources, and we take it a step
  //           further with paper packaging carton boxes that fit as many products
  //           as possible to further reduce use of paper carton boxes.
  //           <br />
  //           <br />
  //           Monolith Nutrition’s sourced USDA certified organic coconuts – the
  //           prime ingredient used for our MCT – are sourced from suppliers who
  //           practise sustainable farming methods.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={coconutImg}
  //               alt="coconut"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Quality MCT sourced from USDA certified organic coconuts.
  //               Consume with confidence!
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Contemporary</div>
  //           <div>
  //             MCT Essentials and MCT LeanEssentials truly represents a modern
  //             and contemporary way to enjoy all the benefits of MCT in a
  //             convenient and enjoyable manner. Easily mixed with your favourite
  //             drinks – whether cold or hot – or blend the sachet’s contents of
  //             flavourful Lemon Blast powder or hearty Chocolate Rapture powder
  //             into a nutritious shake or smoothie that is not only super healthy
  //             but addictively tasty.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             Furthermore, in chic sachet form, take them on the go with you and
  //             consume at your convenience day or night, sunshine or rain! Even
  //             the box itself is small enough to bring along with you!
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={sachetImg}
  //               alt="MCT sachet"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Contemporary, chic and convenient sachets perfect for everyday
  //               free and easy or even on the go lifestyles.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Innovation</div>
  //           <div>
  //             The fundamentals for healthy living has not changed and remains as
  //             simple and straightforward as it has always been. However, with
  //             new ideas, research, and advances in nutrition, exercise, modern
  //             medicine, and an overall improved quality of life, there are more
  //             options today than ever before when it comes to choosing the right
  //             solutions we need and desire to better improve health and
  //             wellness.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             With economies of scale, nutritional experience, an equipped
  //             facility, and technical know-how, Monolith Nutrition avoids the
  //             common OEM and ODM method of product formulation and production.
  //             We innovate across the design, formulation, and process of the
  //             product from start to finish, allowing us to customise and create
  //             MCT products that are unique and one of a kind.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             We wholeheartedly believe that you hold in your hands a
  //             nutritional supplement that will pay dividends to your overall
  //             health and wellness. Be confident that every MCT Essentials or MCT
  //             LeanEssentials sachet that you take gets you closer to good health
  //             and better living.
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     banner: philipBanner,
  //   },
  // ],
  // cognitive: [
  //   {
  //     title: "Mind Your Business",
  //     author: {
  //       name: "Ken Khang Nanyi",
  //       about:
  //         "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
  //       profileImg: kenImg,
  //     },
  //     desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
  //     path: "mind-your-business",
  //     categories: ["LifeStyle", "Beginners"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           Whatever career, profession, or task you undertake, you are
  //           essentially doing some form of business. You put in the work
  //           necessary to achieve your goals so that you get rewarded for your
  //           efforts. You strive to be competent and relevant in your job, field,
  //           and industry in order to deliver value. You learn to work smart and
  //           use leverage so that you can get more done everyday. You work with
  //           the intention of creating passive income, or for a pension or reward
  //           scheme that keeps you comfortable for retirement.
  //           <br />
  //           <br />
  //           Now, let us enlighten ourselves as to how similar building and
  //           maintaining a healthy body is to building a business.
  //           <br />
  //           You put in place a lifestyle that helps you stay fit physically and
  //           mentally. You strive to commit to exercise so that you stay healthy
  //           for a better quality of life. You learn to leverage on knowledge and
  //           nutrition so that your efforts become greater. You build a
  //           foundation for good health towards enjoying a long and fulfilling
  //           life that is free from sickness and disease.
  //           <br />
  //           <br />
  //           See the similarities in concept and essence? Your body is literally
  //           a business – <span className="font-bold">YOUR</span> business.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Building a solid foundation</div>
  //           A successful business or career thrives when a solid foundation
  //           manifests itself from your knowledge, skills and abilities. With
  //           this foundation, you open up the ability to create sustainable
  //           action and systems for further income streams.
  //           <br />
  //           <br />
  //           Your overall well-being and quality of life relies on a healthy
  //           foundation to flourish upon. Neglecting foundational health risks
  //           weakening the immune system, increasing susceptibility to sickness
  //           and disease, and giving way to chronic illnesses.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Staying informed and relevant in a changing environment
  //           </div>
  //           The business landscape has evolved and individuals and organisations
  //           must adapt in order to stay relevant. It’s cliche but it’s true when
  //           they say change is the only constant. But there are fundamentals
  //           that consistently work and remain relevant since time immemorial,
  //           and that is creating value through productive outputs.
  //           <br />
  //           <br />
  //           With new advances, research, and knowledge in health and wellness,
  //           it is important to stay in the loop of current information that is
  //           relevant to good health and improving your quality of life through
  //           wellness.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Use it or lose it</div>
  //           Use your knowledge, skills and abilities to create value; or do not
  //           and let it eventually become forgotten and go to waste. The muscles
  //           of our body when not frequently used eventually atrophy, leading to
  //           infirmities and even sarcopenia. Our mind and brain deteriorates
  //           through time without mental stimulation across the thought and
  //           learning process, leading to a feeble mind and even senility.
  //           <br />
  //           <br />
  //           Make effort and take action to use your mind and muscles, or
  //           eventually lose it.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-500 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={elderYogaImg}
  //               alt="elder doing yoga"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Maintaining healthy muscle is vital for longevity and a more
  //               fulfilling quality of life.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Aspire to create passive reward streams
  //           </div>
  //           We strive to achieve some form of passive income or an appropriate
  //           nest egg that allows us to maintain a desired lifestyle, enjoy
  //           comfortably long way down our golden years, or leave behind a
  //           legacy. When a profitable foundation and sustainable system is in
  //           place, income continues well into the times even when you stop
  //           working. The natural process of thermogenesis occurs in our body
  //           throughout the day even while we are resting or sleeping. This is
  //           part of our body’s metabolism and when harnessed, creates an ideal
  //           and natural environment for optimum weight loss and healthy physical
  //           function. outputs.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-500 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={fitMaleImg}
  //               alt="fit male"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               {
  //                 "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
  //               }
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Your takeaway for a great day</div>
  //           In both business and life in general, making small efforts and
  //           lifestyle tweaks today definitely helps you enjoy tomorrow and
  //           beyond so much more. So mind your business starting from today!
  //         </div>
  //       </div>
  //     ),
  //     banner: kenBanner,
  //   },
  //   {
  //     title: "Our belief towards good health & better living",
  //     author: {
  //       name: "Philip Tan",
  //       about:
  //         "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
  //       profileImg: philipImg,
  //     },
  //     desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
  //     path: "our-belief-towards-good-health-and-better-living",
  //     categories: ["About Us"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           It’s one thing to do something, and another to truly believe it in.
  //           When we truly believe in something, we put all of our heart, mind,
  //           body, and soul into our actions and tasks. There’s just no faking
  //           it.
  //           <br />
  //           <br />
  //           Monolith Nutrition was the culmination of three years of business
  //           strategy brainstorming, nutritional research, product formulation
  //           and tweaking, bonding and team-building, and belief-driven passion.
  //           All of which resulted in the creation of a values and beliefs system
  //           that drives our decision-making towards the pursuit of delivering
  //           nutritional supplements that truly promote good health and better
  //           living across the lives that every humble sachet touches.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Good Health & Better Living</div>
  //           We are laser-focused in formulating foundational nutrition
  //           supplements that are high quality and of great value for good health
  //           and better living. Whether it is MCT derived from USDA certified
  //           organic coconuts, whey protein concentrate, coconut dietary fibre,
  //           European-sourced natural flavourings, or high bioavailability
  //           tocotrienol, expect the best ingredients used across strict
  //           formulation and manufacturing standards.
  //           <br />
  //           <br />
  //           Our flagship products MCT Essentials and MCT LeanEssentials
  //           represent high quality MCT nutritional supplements that do not break
  //           the bank and are suitable for long-term consumption.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Differentiation</div>
  //           Know that you are using nutritional supplements that are unique and
  //           stand out in their own way. We know that MCT is great for health,
  //           but we differentiate ourselves by standing out from the rest by
  //           packing as much coconut MCT rather than filler into every serving
  //           sachet. We are proud to have created a tantalising taste profile
  //           that makes you crave for the next sachet. We have painstakingly
  //           formulated an easy to mix MCT powdered product that mixes amazingly
  //           well with both cold and hot water or drinks – something that many
  //           find difficult without the use of fillers.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Sustainability</div>
  //           Our business actions and activities aim to reduce carbon footprint.
  //           Monolith Nutrition avoids using plastic wrap for our products, and
  //           our box paper packaging is derived from recycled materials and
  //           sustainable forestry. Product box printing utilises sustainable and
  //           eco-friendly vegetable-based inks. MCT Essentials’ small and chic
  //           box and sachet sizes use fewer resources, and we take it a step
  //           further with paper packaging carton boxes that fit as many products
  //           as possible to further reduce use of paper carton boxes.
  //           <br />
  //           <br />
  //           Monolith Nutrition’s sourced USDA certified organic coconuts – the
  //           prime ingredient used for our MCT – are sourced from suppliers who
  //           practise sustainable farming methods.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={coconutImg}
  //               alt="coconut"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Quality MCT sourced from USDA certified organic coconuts.
  //               Consume with confidence!
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Contemporary</div>
  //           <div>
  //             MCT Essentials and MCT LeanEssentials truly represents a modern
  //             and contemporary way to enjoy all the benefits of MCT in a
  //             convenient and enjoyable manner. Easily mixed with your favourite
  //             drinks – whether cold or hot – or blend the sachet’s contents of
  //             flavourful Lemon Blast powder or hearty Chocolate Rapture powder
  //             into a nutritious shake or smoothie that is not only super healthy
  //             but addictively tasty.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             Furthermore, in chic sachet form, take them on the go with you and
  //             consume at your convenience day or night, sunshine or rain! Even
  //             the box itself is small enough to bring along with you!
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={sachetImg}
  //               alt="MCT sachet"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Contemporary, chic and convenient sachets perfect for everyday
  //               free and easy or even on the go lifestyles.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Innovation</div>
  //           <div>
  //             The fundamentals for healthy living has not changed and remains as
  //             simple and straightforward as it has always been. However, with
  //             new ideas, research, and advances in nutrition, exercise, modern
  //             medicine, and an overall improved quality of life, there are more
  //             options today than ever before when it comes to choosing the right
  //             solutions we need and desire to better improve health and
  //             wellness.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             With economies of scale, nutritional experience, an equipped
  //             facility, and technical know-how, Monolith Nutrition avoids the
  //             common OEM and ODM method of product formulation and production.
  //             We innovate across the design, formulation, and process of the
  //             product from start to finish, allowing us to customise and create
  //             MCT products that are unique and one of a kind.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             We wholeheartedly believe that you hold in your hands a
  //             nutritional supplement that will pay dividends to your overall
  //             health and wellness. Be confident that every MCT Essentials or MCT
  //             LeanEssentials sachet that you take gets you closer to good health
  //             and better living.
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     banner: philipBanner,
  //   },
  // ],
  // events: [
  //   {
  //     title: "Mind Your Business",
  //     author: {
  //       name: "Ken Khang Nanyi",
  //       about:
  //         "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
  //       profileImg: kenImg,
  //     },
  //     desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
  //     path: "mind-your-business",
  //     categories: ["LifeStyle", "Beginners"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           Whatever career, profession, or task you undertake, you are
  //           essentially doing some form of business. You put in the work
  //           necessary to achieve your goals so that you get rewarded for your
  //           efforts. You strive to be competent and relevant in your job, field,
  //           and industry in order to deliver value. You learn to work smart and
  //           use leverage so that you can get more done everyday. You work with
  //           the intention of creating passive income, or for a pension or reward
  //           scheme that keeps you comfortable for retirement.
  //           <br />
  //           <br />
  //           Now, let us enlighten ourselves as to how similar building and
  //           maintaining a healthy body is to building a business.
  //           <br />
  //           You put in place a lifestyle that helps you stay fit physically and
  //           mentally. You strive to commit to exercise so that you stay healthy
  //           for a better quality of life. You learn to leverage on knowledge and
  //           nutrition so that your efforts become greater. You build a
  //           foundation for good health towards enjoying a long and fulfilling
  //           life that is free from sickness and disease.
  //           <br />
  //           <br />
  //           See the similarities in concept and essence? Your body is literally
  //           a business – <span className="font-bold">YOUR</span> business.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Building a solid foundation</div>
  //           A successful business or career thrives when a solid foundation
  //           manifests itself from your knowledge, skills and abilities. With
  //           this foundation, you open up the ability to create sustainable
  //           action and systems for further income streams.
  //           <br />
  //           <br />
  //           Your overall well-being and quality of life relies on a healthy
  //           foundation to flourish upon. Neglecting foundational health risks
  //           weakening the immune system, increasing susceptibility to sickness
  //           and disease, and giving way to chronic illnesses.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Staying informed and relevant in a changing environment
  //           </div>
  //           The business landscape has evolved and individuals and organisations
  //           must adapt in order to stay relevant. It’s cliche but it’s true when
  //           they say change is the only constant. But there are fundamentals
  //           that consistently work and remain relevant since time immemorial,
  //           and that is creating value through productive outputs.
  //           <br />
  //           <br />
  //           With new advances, research, and knowledge in health and wellness,
  //           it is important to stay in the loop of current information that is
  //           relevant to good health and improving your quality of life through
  //           wellness.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Use it or lose it</div>
  //           Use your knowledge, skills and abilities to create value; or do not
  //           and let it eventually become forgotten and go to waste. The muscles
  //           of our body when not frequently used eventually atrophy, leading to
  //           infirmities and even sarcopenia. Our mind and brain deteriorates
  //           through time without mental stimulation across the thought and
  //           learning process, leading to a feeble mind and even senility.
  //           <br />
  //           <br />
  //           Make effort and take action to use your mind and muscles, or
  //           eventually lose it.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={elderYogaImg}
  //               alt="elder doing yoga"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Maintaining healthy muscle is vital for longevity and a more
  //               fulfilling quality of life.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">
  //             Aspire to create passive reward streams
  //           </div>
  //           We strive to achieve some form of passive income or an appropriate
  //           nest egg that allows us to maintain a desired lifestyle, enjoy
  //           comfortably long way down our golden years, or leave behind a
  //           legacy. When a profitable foundation and sustainable system is in
  //           place, income continues well into the times even when you stop
  //           working. The natural process of thermogenesis occurs in our body
  //           throughout the day even while we are resting or sleeping. This is
  //           part of our body’s metabolism and when harnessed, creates an ideal
  //           and natural environment for optimum weight loss and healthy physical
  //           function. outputs.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={fitMaleImg}
  //               alt="fit male"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               {
  //                 "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
  //               }
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Your takeaway for a great day</div>
  //           In both business and life in general, making small efforts and
  //           lifestyle tweaks today definitely helps you enjoy tomorrow and
  //           beyond so much more. So mind your business starting from today!
  //         </div>
  //       </div>
  //     ),
  //     banner: kenBanner,
  //   },
  //   {
  //     title: "Our belief towards good health & better living",
  //     author: {
  //       name: "Philip Tan",
  //       about:
  //         "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
  //       profileImg: philipImg,
  //     },
  //     desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
  //     path: "our-belief-towards-good-health-and-better-living",
  //     categories: ["About Us"],
  //     article: (
  //       <div className="fw-300 text-gray">
  //         <div>
  //           It’s one thing to do something, and another to truly believe it in.
  //           When we truly believe in something, we put all of our heart, mind,
  //           body, and soul into our actions and tasks. There’s just no faking
  //           it.
  //           <br />
  //           <br />
  //           Monolith Nutrition was the culmination of three years of business
  //           strategy brainstorming, nutritional research, product formulation
  //           and tweaking, bonding and team-building, and belief-driven passion.
  //           All of which resulted in the creation of a values and beliefs system
  //           that drives our decision-making towards the pursuit of delivering
  //           nutritional supplements that truly promote good health and better
  //           living across the lives that every humble sachet touches.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Good Health & Better Living</div>
  //           We are laser-focused in formulating foundational nutrition
  //           supplements that are high quality and of great value for good health
  //           and better living. Whether it is MCT derived from USDA certified
  //           organic coconuts, whey protein concentrate, coconut dietary fibre,
  //           European-sourced natural flavourings, or high bioavailability
  //           tocotrienol, expect the best ingredients used across strict
  //           formulation and manufacturing standards.
  //           <br />
  //           <br />
  //           Our flagship products MCT Essentials and MCT LeanEssentials
  //           represent high quality MCT nutritional supplements that do not break
  //           the bank and are suitable for long-term consumption.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Differentiation</div>
  //           Know that you are using nutritional supplements that are unique and
  //           stand out in their own way. We know that MCT is great for health,
  //           but we differentiate ourselves by standing out from the rest by
  //           packing as much coconut MCT rather than filler into every serving
  //           sachet. We are proud to have created a tantalising taste profile
  //           that makes you crave for the next sachet. We have painstakingly
  //           formulated an easy to mix MCT powdered product that mixes amazingly
  //           well with both cold and hot water or drinks – something that many
  //           find difficult without the use of fillers.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Sustainability</div>
  //           Our business actions and activities aim to reduce carbon footprint.
  //           Monolith Nutrition avoids using plastic wrap for our products, and
  //           our box paper packaging is derived from recycled materials and
  //           sustainable forestry. Product box printing utilises sustainable and
  //           eco-friendly vegetable-based inks. MCT Essentials’ small and chic
  //           box and sachet sizes use fewer resources, and we take it a step
  //           further with paper packaging carton boxes that fit as many products
  //           as possible to further reduce use of paper carton boxes.
  //           <br />
  //           <br />
  //           Monolith Nutrition’s sourced USDA certified organic coconuts – the
  //           prime ingredient used for our MCT – are sourced from suppliers who
  //           practise sustainable farming methods.
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={coconutImg}
  //               alt="coconut"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Quality MCT sourced from USDA certified organic coconuts.
  //               Consume with confidence!
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Contemporary</div>
  //           <div>
  //             MCT Essentials and MCT LeanEssentials truly represents a modern
  //             and contemporary way to enjoy all the benefits of MCT in a
  //             convenient and enjoyable manner. Easily mixed with your favourite
  //             drinks – whether cold or hot – or blend the sachet’s contents of
  //             flavourful Lemon Blast powder or hearty Chocolate Rapture powder
  //             into a nutritious shake or smoothie that is not only super healthy
  //             but addictively tasty.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             Furthermore, in chic sachet form, take them on the go with you and
  //             consume at your convenience day or night, sunshine or rain! Even
  //             the box itself is small enough to bring along with you!
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="max-w-300 text-center m-l-auto m-r-auto">
  //             <img
  //               className="full-width m-b-10"
  //               src={sachetImg}
  //               alt="MCT sachet"
  //             />
  //             <div className="fw-300 text-gray fs-12 font-italic">
  //               Contemporary, chic and convenient sachets perfect for everyday
  //               free and easy or even on the go lifestyles.
  //             </div>
  //           </div>
  //         </div>

  //         <br />
  //         <br />

  //         <div>
  //           <div className="font-bold m-b-5">Innovation</div>
  //           <div>
  //             The fundamentals for healthy living has not changed and remains as
  //             simple and straightforward as it has always been. However, with
  //             new ideas, research, and advances in nutrition, exercise, modern
  //             medicine, and an overall improved quality of life, there are more
  //             options today than ever before when it comes to choosing the right
  //             solutions we need and desire to better improve health and
  //             wellness.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             With economies of scale, nutritional experience, an equipped
  //             facility, and technical know-how, Monolith Nutrition avoids the
  //             common OEM and ODM method of product formulation and production.
  //             We innovate across the design, formulation, and process of the
  //             product from start to finish, allowing us to customise and create
  //             MCT products that are unique and one of a kind.
  //           </div>

  //           <br />
  //           <br />

  //           <div>
  //             We wholeheartedly believe that you hold in your hands a
  //             nutritional supplement that will pay dividends to your overall
  //             health and wellness. Be confident that every MCT Essentials or MCT
  //             LeanEssentials sachet that you take gets you closer to good health
  //             and better living.
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     banner: philipBanner,
  //   },
  // ],
};

export const shorts = [
  {
    img: testimonialImg1,
    title: "Mix it into your life!",
  },
  {
    img: testimonialImg2,
    title: "Energy for my exercise",
  },
  { img: testimonialImg3, title: "Amazing taste" },
  {
    img: testimonialImg4,
    title: "Clear head clear game",
  },
  { img: testimonialImg5, title: "Everyday goodness" },
];
// Community Blog End
